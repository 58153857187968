import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const isetasanduv = () => (
  <Layout>
    <SEO title="Isetasanduv epomasskate" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>ISETASANDUV EPOMASSKATE</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/epoporandad/antistaatilised/"
          activeStyle={{ border: "30px solid green" }}
        >
          <div className="epo antistaatilised">
            <h2>Antistaatilised</h2>
            <div className="epo-img epo-img1"></div>
          </div>
        </Link>
        <Link to="/epoporandad/epohoordemass/">
          <div className="epo epohoordemass">
            <h2>epohõõrdemass</h2>
            <div className="epo-img epo-img2"></div>
          </div>
        </Link>
        <Link
          to="/epoporandad/isetasanduv/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
        >
          <div className="epo isetasanduv-epomasskate">
            <h2>isetasanduv epomasskate</h2>
            <div className="epo-img epo-img3"></div>
          </div>
        </Link>
        <Link to="/epoporandad/treppidele/">
          <div className="epo treppidele">
            <h2>treppidele</h2>
            <div className="epo-img epo-img4"></div>
          </div>
        </Link>
        <Link to="/epoporandad/dekoratiivporandad/">
          <div className="epo dekoratiivporandad">
            <h2>dekoratiivpõrandad</h2>
            <div className="epo-img epo-img5"></div>
          </div>
        </Link>
        <Link to="/epoporandad/lasketiiru/">
          <div className="epo lasketiiru">
            <h2>Lasketiiru põrandad</h2>
            <div className="epo-img epo-img6"></div>
          </div>
        </Link>
      </div>

      <div className="isetasanduv-wrapper">
        <div className="isetasanduv-text">
          <h3>Isetasanduv epomasskate</h3>
          <p>
            Isetasanduv epomasskate ehk epomass sobib keskmiselt tugeva
            mehaanilise ja keemilise koormusega tööstus-, remondi- ja laoruumide
            põrandatele. Isetaanduvad polüuretaanmassid sobivad ebastabiilse
            aluspinnaga põrandatele. Oma koostiselt on elastne materjal, hea
            kulumiskindlusega. Ideaalne lahendus põrandate renoveerimisel, kus
            on palju pragusid. Võimalik valida väga paljude värvitoonide vahel
            (Nt RAL kataloog).
          </p>
        </div>
        <div className="subpage-img isetasanduv-img"></div>
      </div>
    </div>
  </Layout>
)

export default isetasanduv
